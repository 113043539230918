import React from "react";
import { Container, Nav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Logo from "./Media/Logo 1.png";
import elect from "./Media/elect.mp4";
import "./Gallery.css";
import "./Firstpage.css";
import Pagination from "react-bootstrap/Pagination";
import pstrnick from "./Media/pstrnick.jpeg";
import pstrnick1 from "./Media/pstrnick1.jpeg";
import pstrnick2 from "./Media/pstrnick2.jpeg";
import pstrnick3 from "./Media/pstrnick3.jpeg";
import pstrnick4 from "./Media/pstrnick4.jpeg";
import pstrnick5 from "./Media/pstrnick5.jpeg";
import pstrnick6 from "./Media/pstrnick6.jpeg";
import pstrnick7 from "./Media/pstrnick7.jpeg";
import pstrnick8 from "./Media/pstrnick8.jpeg";
import sarahk from "./Media/sarahk.jpeg";
import sarahk1 from "./Media/sarahk1.jpeg";
import sarahk2 from "./Media/sarahk2.jpeg";
import year1 from "./Media/Year1.jpeg";
import year2 from "./Media/Year2.jpeg";
import year3 from "./Media/Year3.jpeg";
import year4 from "./Media/Year4.jpeg";
import year5 from "./Media/Year5.jpeg";
import year6 from "./Media/Year6.jpeg";
import black1 from "./Media/black1.jpeg";
import black2 from "./Media/black2.jpeg";
import black3 from "./Media/black3.jpeg";
import black4 from "./Media/black4.jpeg";
import black5 from "./Media/black5.jpeg";
import black6 from "./Media/black6.jpeg";
import sade from "./Media/sade.jpeg";
import sade1 from "./Media/sade1.jpeg";
import sade2 from "./Media/sade2.jpeg";
import sade3 from "./Media/sade3.jpeg";
import sade4 from "./Media/sade4.jpeg";
import sade5 from "./Media/sade5.jpeg";
import sade6 from "./Media/sade6.jpeg";
import sade7 from "./Media/sade7.jpeg";
import sade8 from "./Media/sade8.jpeg";
import sade9 from "./Media/sade9.jpeg";

function Gallery() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <div>
      <Navbar bg="#fff" expand="lg">
        <Container>
          <Navbar.Brand>
            <img src={Logo} alt="..." />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/" className="Links">
                  <li>Home</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/About" className="Links">
                  <li>About</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Events" className="Links">
                  <li>Events</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Ministries" className="Links">
                  <li>Ministries</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Gallery" className="Links">
                  <li>Gallery</li>
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="heading">
        <h2
          style={{
            borderBottom: "none",
            textAlign: "center",
            fontWeight: "200",
            color: "#000",
            margin: "0 auto",
            paddingBottom: "2rem",
          }}
        >
          A collection of memories that took place at Redeemed Gospel Church
          -Buruburu.
        </h2>
      </div>
      <Pagination>
        <div className="content">
          <div className="caption">
            <h6>
              The powerful Good news gospel campaign crusade hosted by Rev.
              Felix Kavoi and pastor Anne Kavoi with guest speaker Bishop Danie
              Makara and guest singers Burton King and John Kay on 9th Jan and
              15th Jan 2023
            </h6>
          </div>
          <div className="grid">
            <div className="item-0">
              <img src={sade} alt="..." />
            </div>

            <div className="item-1">
              <img src={sade1} alt="..." />
            </div>
            <div className="item-2">
              <img src={sade2} alt="..." />
            </div>
            <div className="item-3">
              <img src={sade4} alt="..." />
            </div>
            <div className="item-4">
              <img src={sade8} alt="..." />
            </div>
            <div className="item-5">
              <img src={sade5} alt="..." />
            </div>
            <div className="item-6">
              <img src={sade6} alt="..." />
            </div>
            <div className="item-7">
              <img src={sade9} alt="..." />
            </div>
            <div className="item-8">
              <img src={sade4} alt="..." />
            </div>
            <div className="item-9">
              <img src={sade3} alt="..." />
            </div>
            <div className="item-10">
              <img src={sade7} alt="..." />
            </div>
            <div className="item-11">
              <img src={sade8} alt="..." />
            </div>
          </div>
          <div className="caption">
            <h6>
              The Positioning yourself to dominate revival meeting hosted by
              Rev. Felix Kavoi and pastor Anne Kavoi with guest minister Rev.
              Joseph Mutuku senior pastor Redeemed Gospel church Sabuki. on the
              2nd to 8th January 2023
            </h6>
          </div>
          <div className="grid" style={{ marginBottom: "5rem" }}>
            <div className="item-0">
              <img src={black1} alt="..." />
            </div>
            <div className="item-1">
              <img src={black2} alt="..." />
            </div>

            <div className="item-2">
              <img src={black3} alt="..." />
            </div>
            <div className="item-3">
              <img src={black4} alt="..." />
            </div>
            <div className="item-4">
              <img src={black5} alt="..." />
            </div>
            <div className="item-5">
              <img src={black6} alt="..." />
            </div>
          </div>
          <div className="caption">
            <h6>
              A powerful End of Year Revival Meeting hosted by Reverend Felix
              Kavoi and Pastor Anne Kavoi with Guest speaker Apostole Willy
              Tumwine(Uganda) and Musical guests Florence Mureithi and Minister
              Jude Chosen.
            </h6>
          </div>
          <div className="grid" style={{ marginBottom: "5rem" }}>
            <div className="item-0">
              <img src={year1} alt="..." />
            </div>
            <div className="item-1">
              <img src={year2} alt="..." />
            </div>

            <div className="item-2">
              <img src={year3} alt="..." />
            </div>
            <div className="item-3">
              <img src={year4} alt="..." />
            </div>
            <div className="item-4">
              <img src={year5} alt="..." />
            </div>
            <div className="item-5">
              <img src={year6} alt="..." />
            </div>
          </div>

          <div className="caption">
            <h6>
              Then Deputy President William Ruto attends ordination ceremony of
              our Snr Pastor Felix Kavoi to a Reverend office and more pastors
              being ordained to Pastoral office.
            </h6>
          </div>
          <div className="video">
            <video autoPlay muted controls>
              <source src={elect} type="video/mp4" />
            </video>
          </div>
          <div>
            <div className="caption">
              <h6>
                Powerful revival meeting with Pastor Nick Musembi and live
                worship lead by renowned musician Sarah K. which was hosted at
                Redeemed Gospel Church -Buruburu on 3rd August 2022 to 7th
                August 2022.
              </h6>
            </div>
            <div className="grid">
              <div className="item-0">
                <img src={pstrnick} alt="..." />
              </div>

              <div className="item-1">
                <img src={pstrnick1} alt="..." />
              </div>
              <div className="item-2">
                <img src={pstrnick2} alt="..." />
              </div>
              <div className="item-3">
                <img src={sarahk} alt="..." />
              </div>
              <div className="item-4">
                <img src={pstrnick8} alt="..." />
              </div>
              <div className="item-5">
                <img src={pstrnick5} alt="..." />
              </div>
              <div className="item-6">
                <img src={pstrnick6} alt="..." />
              </div>
              <div className="item-7">
                <img src={sarahk1} alt="..." />
              </div>
              <div className="item-8">
                <img src={pstrnick4} alt="..." />
              </div>
              <div className="item-9">
                <img src={pstrnick3} alt="..." />
              </div>
              <div className="item-10">
                <img src={pstrnick7} alt="..." />
              </div>
              <div className="item-11">
                <img src={sarahk2} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </Pagination>
    </div>
  );
}

export default Gallery;
