import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Media/Logo 1.png";
import pastor from "./Media/pastor.png";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import "./About.css";

function About() {
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <Navbar bg="#fff" expand="lg">
        <Container>
          <Navbar.Brand>
            <img src={Logo} alt="..." />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/" className="Links">
                  <li>Home</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/About" className="Links">
                  <li>About</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Events" className="Links">
                  <li>Events</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Ministries" className="Links">
                  <li>Ministries</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Gallery" className="Links">
                  <li>Gallery</li>
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="About">
        <div className="churchName">
          <h2>Feel at Jesus' feet</h2>
        </div>
      </div>
      <div className="content">
        <div className="Welcome">
          <h2>Our Story</h2>
          <h5>
            Rev. Felix Kavoi was called by God into the Ministry in 1990 in a
            clear night vision while still serving in HURUMA RGC( House of
            prayer for all people) as a Choir member, usher and a member of the
            Evangelistic ministry. Later, he was posted to R.G.C. Kariokor in
            August 1994 as the Lead Pastor. In 1996, January, he moved on to
            serve at NEW Life City Temple as an assistant Pastor. On 10th August
            1997, Rev. Felix Kavoi under the leading of the Holy Spirit, planted
            the Branch of Redeemed Gospel Church in Buruburu. Redeemed Gospel
            Church Buruburu held their first worship service on 10th August
            1997, startng with only six (6) people. The same day, God added that
            Number to 13 people by the end of the service and one soul ( a
            mature mother) gave her life to Jesus Christ. This congregation
            started in a humble place , the home garage (car park shade) at
            Happy land Nursery school. Growth started to happen, though slowly
            but sure. Rev. Felix Kavoi started to challenge the few members
            (approx. 35 people) over one year that we needed to hire (lease) a
            piece of land to build a “Cathedral” as he loved to put it. His
            words bore fruit and in March 1999, through much effort, we managed
            to lease a piece of land and constructed our first Church Sanctuary
            (shown on the photo) It took us almost one full year to finish
            building the small structure of 25feet by 50feet. However, the owner
            of the piece of land required her land back and told us that she
            would not renew the lease of two years we had signed. it was
            discouraging to us but we refused to give up. Rev. Felix Kavoi,
            again being the Man of faith he is, this time round challenged the
            congregation to commit ourselves to buy our own piece of land to
            build the Lord a “Cathedral “. Both leaders and the congregation
            stood with the man of God . After a rigorous time of fund-raising ,
            we managed to raise 100,000/=. Rev. Kavoi negotiated for 2 plots of
            30' x 50' ( Total space = 30' x 100') and signed an agreement that
            he would buy each plot for 500,000/= making a total of 1 Million).
            This was a tall order and only by Faith could he commit himself and
            the church. This enabled the congregation to move to their New piece
            of land in February 2001. We embarked on building our second
            sanctuary while still paying for the land. It was a gruesome
            struggle and this was the most difficult time the Rev. and his
            congregation had ever had. Severally the owner of the piece of land
            threatened to come for his money on Sunday during the services. Our
            Spiritual Papa the Arch Bishop stepped in to help to fund-raise. The
            -3- -3- -4- payment plan that was for one year finally took over 3
            years. We completed paying in April 2004. In August 2004, the Lord
            spoke to Rev. Kavoi again to move the church to the current
            location. There was great urgency in the voice that he heard. When
            he shared the vision to move the church next to the road, the
            building commiƩee comprising of 10 working members of the church
            protested. They leŌ the church immediately. For the first time, Rev.
            Kavoi was frightened and for 4 months, he never spoke about it to
            the congregation. In January 2005, a preacher from South Africa
            preached that it was “Time to move Forward”. It was a direct
            prophetic word and he directly prophesied to Rev. Kavoi that he
            should not be afraid. Rev. Kavoi, again gathered all his strength
            and that day, aŌ er the service he restructured the Building
            commiƩee and immediately announced the vision to relocate the church
            to the frontage of the tarmac. Although most of well to do members
            had leŌ the church, God instructed him to use the faithful leaders
            he had in the church. For sure Most of them were very poor and
            jobless. But God was with them. They assured Rev. Kavoi it was
            possible and they took up the maƩ er and planned their first
            fund-raising towards relocating the church next to the tarmac. To
            the surprise of many, in 2 months we raised over 280,000/= for the
            first time. This was impossible previously and yet there were more
            well to do people. God moved mightily and we found a buyer for the
            church plot that we were siƫng on. The buyer paid 1.56 M cash and we
            knew this was the hand of God helping us to relocate. In 2 months we
            purchased the 1st plot on the land where this sanctuary stands today
            and started to build our 3rd Sanctuary. On 19th June 2005, we moved
            the congregati on to this land where we are all gathered today.
            Approximately a congregation of 400 people. Between 2005 to 2013, it
            was a time to expand our territories. We embarked on buying the
            plots that were next to the new church location. In 2008 we added
            one more plot and also embarked on expanding the sanctuary. This
            became our 4 th Sanctuary to build. In 2010 we bought the next plot
            and in 2012 we bought the last plot on our right. In 2013, we went
            back to the person who initially had sold the first plot to us and
            negotiated for a piece of land he had remained with. In the same
            year he accepted to sell it to the church for 4M. Since there was no
            any other land available around us, we were forced to take it by all
            means. Rev. Kavoi and the Local Church Council decided to start the
            construction of the main Sanctuary. ( Cathedral, as Rev. Kavoi loved
            to call it).
          </h5>
        </div>
        <div className="Welcome">
          <h2>Exhortation</h2>
          <h5>
            We care about you and have created the right environment for your
            heart to be blessed. We hope you’ll find our pragmatic approach to
            church worship and preaching very refreshing. This is driven by a
            constant feeling that we exist to connect people to Jesus and each
            other. At our sanctuary, we will freely embrace you no matter what
            you have gone through in life because we believe that nobody is a
            nobody and everybody is a somebody. People have found grace to turn
            around their lives as we worship. Join our services this week and
            enjoy the abundance of grace and joy in every service.
          </h5>
        </div>
        <div className="Welcome2">
          <h2>Reverend Felix Kavoi & Pastor Ann Kavoi</h2>
          <img src={pastor} alt="..." data-aos="fade-up" />
          <h5>
            Reverend Felix and Pastor Ann Kavoi have dedicated their lives to
            serving God and ministering the Lord’s word since 1997. Reverend
            Felix is a A husband, father, brother uncle and friend. Theologian
            and teacher. Passionate about God's kingdom, committed to preaching
            unadulterated Gospel of Jesus Christ. He is actively on multiple
            missionary work traveling to different parts of the world, spreading
            The Lord’s gospel. He is currently planning a missionary trip
            scheduled to take place later on this year.
          </h5>
          <h4 style={{ color: "#926F34" }}>A word from Reverend Felix Kavoi</h4>
          <h5>
            Faith that is untried is not faith and the trial of Faith does not
            happen within the confines of comfort. Our big faith encountered
            trials of equal measure! For example, what would you do if you had
            paid fee for all the approvals of the building, structural and
            Architectural only to discover that the person who you depended on
            conned you of all the money and brought to you fake documents. And
            all this is happening when the building is already off the ground.
            This is what I am talking about. Such and many more were our
            experiences for daring to walk by Faith. As the visionary, sometimes
            one gets tired of appealing all the time for more funds from the
            members, but it is worst when giving fatigue sets on the members
            themselves.. Through it all, finally here we stand to celebrate the
            doing of the Lord. I have learnt important lessons of Faith and this
            can only be captured well in a book on this kind of daring Faith. A
            project of this Magnitude is an enough empirical resource for any
            study material on the subject of Faith. But to make it easy and
            simple, let me say this; God is not found in the parking lot, but on
            the fastest lane of the highway. What many people call faith is NOT
            faith but hope! Faith is a NOW WORD. No one who has faith waits for
            tomorrow. If you have faith, start NOW!!!. This may appear
            negligible, but the truth is, this is where Millions of people,
            including many servants of God are stuck. Faith requires an
            immediate step forward, which is largely lacking in many places
            where people want to see God. Thankyou so much friends, Great
            servants of God and the great members of Redeemed Gospel Church
            Buruburu. May the almighty God also show HIS faithfulness to anyone,
            who would dare to believe that it is possible.
          </h5>
          <h5>Reverend Felix Kavoi</h5>
          <h6>Lead Minister RGC BuruBuru</h6>
        </div>
      </div>
      <div className="footer">
        <iframe
          title="Map to church"
          width="600"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=redeemed%20gospel%20church%20buruburu&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
      <div className="contacts">
        <h4>Get in touch with us.</h4>
        <div>
          <h6>We'd love to hear from you</h6>
          <h5>Redeemed Gospel Church Buruburu (Blessed Hope Centre)</h5>
          <h6>P.O.BOX 32167 – 00600 Nairobi, Kenya.  </h6>
          <h6>Rabai Road, near Buruburu Girls high school,</h6>
        </div>

        <div>
          <h6>Tel: 020-2110772</h6>
          <h6>Mobile1: (+254) 722 385 747 / (+254) 721 449 353</h6>
          <h6>E-mail: info@rgcburuburu.org/ blessedhopecentre@ymail.com</h6>
        </div>
      </div>
    </div>
  );
}

export default About;
