import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Firstpage from "./Components/Firstpage";
import About from "./Components/About";
import Ministries from "./Components/Ministries";
import Events from "./Components/Events";
import Gallery from "./Components/Gallery";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Firstpage />} />
        <Route path="/About" element={<About />} />
        <Route path="/Ministries" element={<Ministries />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Gallery" element={<Gallery />} />
      </Routes>
    </Router>
  );
}

export default App;
