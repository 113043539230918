import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Media/Logo 1.png";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import "./Events.css";

function Events() {
  return (
    <div>
      <Navbar bg="#fff" expand="lg">
        <Container>
          <Navbar.Brand>
            <img src={Logo} alt="..." />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/" className="Links">
                  <li>Home</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/About" className="Links">
                  <li>About</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Events" className="Links">
                  <li>Events</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Ministries" className="Links">
                  <li>Ministries</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Gallery" className="Links">
                  <li>Gallery</li>
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="Events">
        <div className="churchName">
          <h2>And Jesus is The Lord</h2>
        </div>
      </div>
      <div className="events">
        <h2>Events</h2>
        <div className="eventcard">
          <h3>Faith For Breakthrough</h3>
          <h4>Lunch Hour Service</h4>
          <h6>
            Come and recieve from the Lord during the powerfull lunch hour
            services. The ministers during the services will be Rev. Felix Kavoi
            and Pastor Anne Kavoi. The services will go on throughout the week
            of 29th August till 2nd September 2022 from 12:30pm to 2:00pm at
            Redeemed Gospel Church -Buruburu.
          </h6>
        </div>
        <div className="eventcard">
          <h3>Annual international faith summit</h3>
          <h4>Every August</h4>
          <h6>
            Ministers from around the world are invited to preach and minister
            the word of God in this week long event. People from around the
            world are welcome and food and accomodation is offered for the
            duration of the event.
          </h6>
        </div>
        <div className="eventcard">
          <h3>Annual free medical camp</h3>
          <h4>Immediately after easter.</h4>
          <h6>
            Dentists opticians and medical doctors come to church to treat the
            sick. Medicine required is also offered free of charge. It is open
            to all even if you are not a member of the church.
          </h6>
        </div>
        <div className="service">
          <h2>Order of services</h2>
          <div className="details">
            <h3>Main service times</h3>
            <ol>
              <li>Sundays—6:30am-8:30am, 8:30am-11.00am & 11:15am-1.30pm</li>
              <li>Wednesdays—6:30pm-8.30pm (Bible Study)</li>
              <li>Lunch Hour-12pm-2pm Monday-Friday </li>
            </ol>
          </div>
          <div className="details">
            <h3>Prayer Services</h3>
            <ol>
              <li>Morning Glory-Monday–Saturday—5am-6am</li>
              <li>Prayer Day-Monday 5:30pm-8.00pm</li>
              <li>Lunch Hour-12pm-2pm Monday-Friday </li>
            </ol>
          </div>
          <div className="details">
            <h3>Recources and Media</h3>
            <h6>
              Get access to a variety of materials to enrich and grow your faith
              in God. Get Christian books, sermons, music, videos and plays at
              affordable prices.
            </h6>
            <h6>
              Missed a message? Want to hear it again? Find weekly sermons from
              our Church here.
            </h6>
          </div>
        </div>
      </div>{" "}
      <div className="footer">
        <iframe
          title="Map to church"
          width="600"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=redeemed%20gospel%20church%20buruburu&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
      <div className="contacts">
        <h4>Get in touch with us.</h4>
        <div>
          <h6>We'd love to hear from you</h6>
          <h5>Redeemed Gospel Church Buruburu (Blessed Hope Centre)</h5>
          <h6>P.O.BOX 32167 – 00600 Nairobi, Kenya.  </h6>
          <h6>Rabai Road, near Buruburu Girls high school,</h6>
        </div>

        <div>
          <h6>Tel: 020-2110772</h6>
          <h6>Mobile1: (+254) 722 385 747 / (+254) 721 449 353</h6>
          <h6>E-mail: info@rgcburuburu.org/ blessedhopecentre@ymail.com</h6>
        </div>
      </div>
    </div>
  );
}

export default Events;
