import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Media/Logo 1.png";
import women from "./Media/Women.jpeg";
import men from "./Media/men.jpeg";
import marriage from "./Media/Marriage.png";
import youth from "./Media/Youth.jpeg";
import hbg from "./Media/hbg.png";
import children from "./Media/children.png";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import "./Ministries.css";

function Ministries() {
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <Navbar bg="#fff" expand="lg">
        <Container>
          <Navbar.Brand>
            <img src={Logo} alt="..." />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/" className="Links">
                  <li>Home</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/About" className="Links">
                  <li>About</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Events" className="Links">
                  <li>Events</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Ministries" className="Links">
                  <li>Ministries</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Gallery" className="Links">
                  <li>Gallery</li>
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="Ministry">
        <div className="churchName">
          <h2>Where Everybody is Somebody</h2>
        </div>
      </div>
      <div className="content">
        <div className="Ministries">
          <div className="Ministriessection">
            <h2>Women Ministry</h2>
            <div className="msection">
              <img src={women} alt="..." />
              <div className="ministriesInfo">
                <h6>
                  Women have a very special part in kingdom growth and hence the
                  need to be empowered and recharged at all times to take up
                  their kingdom mandates. The Blessed Hope Centre Women’s
                  ministry seeks to grow and nurture
                </h6>
                <ol>
                  <li>Confident Women</li>
                  <li>Fruitful Women</li>
                  <li>Chamions in the Market place</li>
                  <li>Worshippers</li>
                  <li>Intercessors</li>
                  <li>Raise Ministers from women</li>
                </ol>
                <h6>
                  Guiding Verse: Proverbs 31:29 Many daughters have done
                  virtuously, nobly, and well with the strength of character
                  that is steadfast in goodness, but you excel them all
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="Ministriesleft">
          <div className="Ministriessectionleft">
            <h2>Men Ministry</h2>
            <div className="msectionleft">
              <img src={men} alt="..." data-aos="fade-" />
              <div className="ministriesInfoleft">
                <h6>
                  This is the Men’s ministry of Blessed Hope centre that
                  provides the men with a place to plug in. here programs that
                  teach and focus men on the foundations of manhood, how to be
                  leaders at home, and making a difference in society are taught
                  and modeled.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="Ministries">
          <div className="Ministriessection">
            <h2>Marriage Ministry</h2>
            <div className="msection">
              <img src={marriage} alt="..." data-aos="fade-up" />
              <div className="ministriesInfo">
                <h6>
                  A marriage needs a good support system. The purpose of this
                  ministry is to help couples establish a successful,
                  fulfilling, joyous, lifelong marriage that brings glory to God
                  and allows the couple to glorify God and enjoy Him and each
                  other in their union. There is a gap that needs to be filled
                  and The Blessed Hope Centre chooses to be proactive concerning
                  the marriage enrichment/post marital programs. Blessed Hope
                  Centre will roll out marriage enrichment workshops, holiday
                  getaways, games, projects, dinners e.t.c that will improve the
                  quality of marriages in the church whose themes will follow in
                  the continuity of the pre-marital course outline. Marriage is
                  the hub where God is raising the transformed generation.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="Ministriesleft">
          <div className="Ministriessectionleft">
            <h2>Youth Ministry</h2>
            <div className="msectionleft">
              <img
                src={youth}
                alt="..."
                style={{ height: "20.326875rem" }}
                data-aos="fade-up"
              />
              <div className="ministriesInfoleft">
                <h6>
                  To BUILD youths and students by growing in relationship and
                  leading them to God, to live with PASSION that is raw,
                  powerful and effective and to be a CATALYST. As God
                  continually changes us, we in turn will transform this city!
                  The ministry exists to courageously lead our young people to
                  become life-long followers of Jesus Christ by being kingdom
                  champions in their families, schools, workplace and city.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="Ministries">
          <div className="Ministriessection">
            <h2>Home fellowship Ministry</h2>
            <div className="msection">
              <img src={hbg} alt="..." data-aos="fade-up" />
              <div className="ministriesInfo" style={{ margin: "0" }}>
                <h6>
                  After you’ve connected with God through Weekly Services, the
                  best way to grow deeper is by joining a Small Group near your
                  neighborhood.  Life is not meant to be lived alone. You were
                  created to experience life with other people, and that’s why
                  at BHC, we encourage you to get into a home fellowship groups-
                  a group of three or more people who gather each week in a
                  home, workplace to bring people together. We believe God
                  created us to live in relationship with others and only then
                  can we live the full life He intends for us. Sharing life
                  through community is part of our design, but meaningful
                  relationships aren’t always easy to find. That’s why home
                  fellowship groups exist—to make these life-changing
                  relationships relevant and accessible to you. In a home
                  fellowship group you’ll hang out, study the Word, and pray
                  together, a great way to grow deeper in your faith with those
                  you enjoy being around.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="Ministriesleft" style={{ minHeight: "46.875rem" }}>
          <div className="Ministriessectionleft">
            <h2>Kids/Children Ministry</h2>
            <div className="msectionleft">
              <img src={children} alt="..." data-aos="fade-up" />
              <div className="ministriesInfoleft">
                <h6>
                  A Place Where Kids Learn About Jesus on their Level. We are so
                  passionate about kids learning about Jesus in a way they can
                  understand. To raise up a godly generation of children who
                  love, revere, trust and serve the Lord Jesus Christ
                  holistically (Joyfully. Faithfully. Gloriously). We believe
                  firmly that Jesus Christ is our Lord and Savior. We aim to
                  train up the children to be grounded to this truth and to
                  build their values and principles based on the word of God.
                  Our desire is that the children discover their identity in
                  Christ and grow to be confident, powerful disciples of Jesus
                  Christ and to live out their purpose here on Earth. Our
                  Mission: Teaching the word of God, instilling obedience and
                  good morals in the lives of our children so that they can be
                  the light in the world. Our Vision: Raising the next
                  generation of Kingdom champions, to grow and develop to their
                  maximum potential, teaching them who they are in Christ.
                  Proverbs 22:6 (Amp) “Train up a child in the way he should go
                  [and in keeping with his individual gift or bent], and when he
                  is old he will not depart from it” We encourage parents of the
                  BHC Kids to get acquainted with the teaching team and discuss
                  the progress of their children and also exchange ideas and
                  suggestions that will help grow the ministry.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <iframe
          title="Map to church"
          width="700"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=redeemed%20gospel%20church%20buruburu&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
      <div className="contacts">
        <h4>Get in touch with us.</h4>
        <div>
          <h6>We'd love to hear from you</h6>
          <h5>Redeemed Gospel Church Buruburu (Blessed Hope Centre)</h5>
          <h6>P.O.BOX 32167 – 00600 Nairobi, Kenya.  </h6>
          <h6>Rabai Road, near Buruburu Girls high school,</h6>
        </div>

        <div>
          <h6>Tel: 020-2110772</h6>
          <h6>Mobile1: (+254) 722 385 747 / (+254) 721 449 353</h6>
          <h6>E-mail: info@rgcburuburu.org/ blessedhopecentre@ymail.com</h6>
        </div>
      </div>
    </div>
  );
}

export default Ministries;
