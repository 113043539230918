import React from "react";
import Logo from "./Media/Logo 1.png";
import vid from "./Media/IFS 2023.mp4";
import tv from "./Media/ebn-tv-official-logo 1.png";
import line from "./Media/Line 3.png";
import ytube from "./Media/ytube 1.png";
import blog from "./Media/sanctuary dedication.jpg";
import ExampleDoc from "./Media/RGC BURUBURU CHURCH 877.pdf";
import faith from "./Media/faith.jpg";
import medical from "./Media/medical.jpeg";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import "./Firstpage.css";
import { Container, Nav } from "react-bootstrap";

function Firstpage() {
  React.useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <Navbar bg="#fff" expand="lg">
        <Container>
          <Navbar.Brand>
            <img src={Logo} alt="..." />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/" className="Links">
                  <li>Home</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/About" className="Links">
                  <li>About</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Events" className="Links">
                  <li>Events</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Ministries" className="Links">
                  <li>Ministries</li>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/Gallery" className="Links">
                  <li>Gallery</li>
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="Header">
        <div className="churchName">
          <h3 style={{ color: "#926F34" }}>Welcome to</h3>
          <h2 style={{ color: "#926F34" }}>Blessed hope centre</h2>
          <h3 style={{ color: "#926F34" }}>BuruBuru</h3>
        </div>
      </div>
      <div className="content">
        <div
          className="Welcome"
          style={{
            color: "#000",
          }}
        >
          <h2
            style={{
              borderBottom: "none",
              textAlign: "center",
              fontWeight: "200",
              color: "#000",
            }}
          >
            "Surely God is in this place"
          </h2>
          <h3
            style={{
              color: "#000",
              padding: "0.5rem",
            }}
          >
            Genesis 28:16
          </h3>
        </div>
        <div className="Welcome">
          <h2>Welcome and feel at Jesus' feet.</h2>
          <h5>
            At Blessed Hope Centre, we embrace the core ingredients of the
            Christianity Faith and present them in a way that is real, bold,
            smart and fun. Our driving desire is to encourage everyone, share
            the biblical message that Jesus Christ is the Saviour, author of our
            Salvation, that He loves every man unconditionally, and that He
            desires a personal, one-on-one relationship with all men.{" "}
          </h5>
          <button onClick={() => navigate("/About")}>Learn More</button>
        </div>
        <div className="events">
          <h2>Recent Sermons</h2>
          <div className="cards">
            <div className="eventscard">
              <iframe
                width="450"
                height="450"
                src="https://www.youtube.com/embed/rg-eBAs2_Zo"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
              ></iframe>
              <div className="eventsinfo">
                <h4 style={{ fontWeight: "400" }}>
                  Your Faith Today With Rev. Felix Kavoi
                </h4>
              </div>
            </div>
            <div className="eventscard2">
              <iframe
                width="450"
                height="450"
                src="https://www.youtube.com/embed/I7ptcHBAF3I"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="eventsinfo">
                <h4 style={{ fontWeight: "300" }}>
                  Your Faith Today With Rev Felix Kavoi
                </h4>
              </div>
            </div>
          </div>

          <a
            className="maglink"
            href="https://www.youtube.com/c/EcclesiaBroadcastingNetwork"
          >
            See All Videos
          </a>
        </div>

        <div className="events">
          <h2>Events</h2>
          <div className="cards">
            <div className="eventscard">
              <img
                src={faith}
                alt="..."
                data-aos="fade-in"
                style={{ height: "18rem" }}
              />
              <div className="eventsinfo" onClick={() => navigate("/Events")}>
                <h2>International Faith Summit</h2>
                <h4>14th - 18th August 2023</h4>
                <h6>
                  Redeemed Gospel Church is hosting the annual International
                  faith summit from the 14th t0 the 18th of August 2023. Come
                  and join believers from all over the world with guest speakers
                  who will lead in delivering the theme of the year: Standing
                  For The Faith Amidst Challenges. All are welcome!
                </h6>
              </div>
            </div>
            <div className="eventscard2" onClick={() => navigate("/Events")}>
              <img
                src={medical}
                alt="..."
                data-aos="fade-in"
                style={{ height: "18rem" }}
              />
              <div className="eventsinfo" onClick={() => navigate("/Events")}>
                <h2>Free Medical camp 2023</h2>
                <h4>Friday 14th - Saturday 15th April 2023</h4>
                <h6>
                  Redeemed Gospel Church is hosting the annual free medical camp
                  on Friday 14th to Saturday 15th April 2023 from 9:00 am to
                  4:00pm daily. Come and get medical services suc as general
                  consultation, Dental Extraction, Eye services, cancer
                  screening and councelling. All are welcome!
                </h6>
              </div>
            </div>
          </div>
          <button onClick={() => navigate("/Events")}>See All Events</button>
        </div>

        <div className="Watchus">
          <h2>Watch us Live</h2>
          <div className="socials">
            <div className="tv">
              <img src={tv} alt="..." />
              <div className="tvInfo">
                <div className="channel">
                  <h6>Startimes</h6>
                  <img src={line} alt="..." />
                  <h6>Channel 484</h6>
                </div>
                <div className="channel">
                  <h6>Gotv</h6>
                  <img src={line} alt="..." />
                  <h6>Channel 484</h6>
                </div>
                <div className="channel">
                  <h6>Pang</h6>
                  <img src={line} alt="..." />
                  <h6>Channel 484</h6>
                </div>
              </div>
            </div>
            <div className="yTube">
              <img src={ytube} alt="..." />
              <div className="yTubeInfo">
                <h6>Click here to watch previous sermons</h6>
                <h6>Or</h6>
                <h6>Visit Redeemed Gospel Church on YouTube</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="services">
          <h2>Order of services</h2>
          <div className="order">
            <div className="sunday">
              <h3>Sunday services</h3>
              <h6>First Service - 7:30 to 10:00</h6>
              <h6>Second service - 10:30 to 12:45</h6>
            </div>
            <div className="midweek">
              <h3>Mid-Week Services</h3>
              <h6>Monday prayers from 6:00pm</h6>
              <h6>HBC fellowship Tuesdays from 6:00pm</h6>
              <h6>Wednesday service from 6:00pm</h6>
            </div>
          </div>
        </div>
        <div className="Blog">
          <h2>Blogs</h2>
          <div className="blogInfo">
            <img src={blog} alt="..." />
            <div className="blogText">
              <h3>Sanctuary Dedication Magazine</h3>
              <h6>
                The church was dedicated and officially opened. Please click on
                the button below to download and read the story of the
                sanctuary's construction, the Minister's faith, all the
                congatulatory messages as well as images of the progress of the
                sanctuary's construction.
              </h6>
            </div>
          </div>
          <a
            href={ExampleDoc}
            download="Sanctuary dedication magazine"
            className="maglink"
          >
            Download Magazine
          </a>
        </div>
      </div>
      <div className="footer">
        <iframe
          title="Map to church"
          width="100%"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=redeemed%20gospel%20church%20buruburu&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
      <div className="contacts">
        <h4>Get in touch with us.</h4>
        <div>
          <h6>We'd love to hear from you</h6>
          <h5>Redeemed Gospel Church Buruburu (Blessed Hope Centre)</h5>
          <h6>P.O.BOX 32167 – 00600 Nairobi, Kenya.  </h6>
          <h6>Rabai Road, near Buruburu Girls high school,</h6>
        </div>

        <div>
          <h6>Tel: 020-2110772</h6>
          <h6>Mobile1: (+254) 722 385 747 / (+254) 721 449 353</h6>
          <a href="mailto:rgcburuburu@gmail.com ">
            <h6>E-mail: rgcburuburu@gmail.com</h6>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Firstpage;
